import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index.html',
    redirect: '/home'
  },
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/views/FeaturesPage.vue')
  },
  {
    path: '/who-it-is-for',
    name: 'who-it-is-for',
    component: () => import('@/views/WhoItIsFor.vue')
  },
  {
    path: '/why-us',
    name: 'why-us',
    component: () => import('@/views/WhyUs.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/ArticlesPage.vue')
  },
  {
    path: '/articles/detail/:articleId',
    name: 'detail-article',
    component: () => import('@/views/DetailArticle.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    window.scrollTo(0,0);
  }
})

export default router
