import { createStore } from 'vuex'
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  modules: {
  },
  state: {
    loading: true,
    navItems: [
      {name: 'home', label: 'HOME'},
      {name: 'features', label: 'FEATURES'},
      {name: 'who-it-is-for', label: 'WHO IT\'S FOR'},
      {name: 'why-us', label: 'WHY US'},
      {name: 'articles', label: 'ARTICLES'}
    ]
  },
  getters: {
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value
    }
  },
  actions: {
  },
  plugins: [vuexLocal.plugin]
})
