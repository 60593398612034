import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/colors.scss'
import '@/assets/css/style.scss'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import VueLazyload from "vue-lazyload";

const vuetify = createVuetify({
    components,
    directives,
})

createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)
    .use(VueLazyload, {
        preLoad: 1.3,
        attempt: 1
    })
    .mount('#app')
