<template>
    <div>
        <NavBar/>
        <LoadingCircle v-if="store.state.loading" />
        <router-view v-else/>
        <FooterSide/>
    </div>
</template>

<script setup>
import NavBar from "@/components/NavBar";
import FooterSide from '@/components/FooterSide';
import {useStore} from "vuex";
import LoadingCircle from "@/components/LoadingCircle.vue";
import {onBeforeMount} from "vue";

const store = useStore()

onBeforeMount(() => {
    window.addEventListener("load", () => {
        store.commit("setLoading", false)
    })
})
</script>

<style lang="scss">

</style>
