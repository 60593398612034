<template>
  <div class="m-0 p-0 dropdown-container">
    <label 
      class="selectedItem"
      :class="{'disabled': props.disable }"
      @click="listItemOpened = !listItemOpened"
    >
      {{ props.selectedItem.label }}
    </label>
    <ul class="m-0 p-0" v-if="listItemOpened">
      <li v-for="(item, index) in props.items"
        :key="index"
        @click="selectItem(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { defineEmits } from 'vue';

const emit = defineEmits(['selectItem'])

const props = defineProps({
  items: {
    type: Array
  },
  selectedItem: {
    name: 'home',
    label: 'HOME'
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

let listItemOpened = ref(false)

function selectItem(item) {
  listItemOpened.value = false
  emit('selectItem', item)
}

</script>

<style scoped lang="scss">
.dropdown-container {
  width: 160px;
  height: 100%;
  max-height: 100px;
}

.selectedItem {
  display: flex;
  max-height: 100px;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 0.175rem solid var(--primary-light-blue);
  font-size: 1rem;
  font-weight: bold;
}

ul {
  list-style-type: none;
  background-color: white;
  transition: .15s ease-in-out;

  li {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    border-bottom: 1px solid var(--option-1);

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>