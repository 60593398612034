
const mixin = {
    type2Text: {
        'all': 'All',
        'blog': 'Blog',
        'customer-stories': 'Customer Stories',
        'whats-new': "What's New",
        'business': 'Business'
    },
    bookADemo: async function () {
        // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Calendly.initPopupWidget({url: 'https://calendly.com/payrollengine/30min'});
        return false;
    }
}

export default mixin
