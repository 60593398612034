<template>
    <div class="position-relative" style="z-index: 2">
        <nav class="d-flex row justify-content-center justify-content-sm-around align-items-center">
            <div
                    class="d-flex justify-content-around align-items-center col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 h-100">
                <router-link to="/">
                    <img class="icon-logo" src="@/assets/images/Logo.png" alt="">
                </router-link>

                <div class="d-flex ms-5 h-100 col-10 pages-web-mode">
                    <ul class="d-flex gap-4">
                        <li
                                class="d-flex align-items-center h-100"
                                v-for="navItem in store.state.navItems" :key="navItem.name"
                                :class="{ 'active': route.path.includes(navItem.name) }"
                        >
                            <router-link
                                    :class="{ 'active': route.path.includes(navItem.name) }"
                                    :to="'/' + navItem.name"
                            >{{ navItem.label }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="mt-auto h-100 pages-mobile-mode">
                  <DropDown 
                    :items="store.state.navItems"
                    :selectedItem="selectedPage"
                    @selectItem="(page) => router.push(page.name)">
                  </DropDown>
                </div>
            </div>

            <div class="col-5 col-md-3 text-end">
                <button @click="mixin.bookADemo">Book a demo</button>
            </div>
        </nav>
        <hr/>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import mixin from "@/mixins/mixin";
import DropDown from "./DropDown.vue";

const route = useRoute()
const router = useRouter()
const store = useStore()

let selectedPage = computed(() => {
    let selectedPage = store.state.navItems.find(item => item.name === route.name)
    return selectedPage ? selectedPage : { name: 'home', label: 'HOME' }
})
</script>

<style scoped lang="scss">
@media (max-width: 835px) {
  .pages-web-mode {
    display: none !important;
  }
}

@media (min-width: 836px) {
  .pages-mobile-mode {
    display: none;
  }
}

nav {
  height: 80px;
}

a {
  text-decoration: unset;

  img {
    width: unset;
  }
}

hr {
  margin: unset;
  opacity: 0.2;
  border: 1px solid var(--secondary-purple);
}

ul {
  list-style-type: none;
  flex-direction: row;
  padding: unset;
  margin: unset;

  li {
    width: max-content;
  }

  li.active {
    border-bottom: 3px solid var(--primary-light-blue);
  }
}

.icon-logo {
  width: 90px;
  height: 40px;
}
</style>
